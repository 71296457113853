var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var win;

if (typeof window !== "undefined") {
  win = window;
} else if (typeof _global !== "undefined") {
  win = _global;
} else if (typeof self !== "undefined") {
  win = self;
} else {
  win = {};
}

exports = win;
export default exports;